/* eslint-disable */
export default [
    {
        path: '/report/home',
        name: 'report',
        component: () => import('@/views/report/Report.vue'),
        meta: {
            pageTitle: 'BI Report',
            breadcrumb: [
                {
                    text: 'DSS',
                },
                {
                    text: 'BI Report',
                    active: true,
                },
            ],
            layout: 'report'
        },
        props: route => ({ query: route.query.code })
    },
    {
        path: '/dashboard/da-cntt',
        name: 'da-cntt',
        component: () => import('@/views/dashboard/DaCNTT.vue'),
        meta: {
            pageTitle: 'BI Report',
            breadcrumb: [
                {
                    text: 'DSS',
                },
                {
                    text: 'BI Report',
                    active: true,
                },
            ],
            layout: 'dashboard'
        },
        props: route => ({ query: route.query.code })
    },
    /*--------------App---------------*/
    {
        path: '/apps/calendar',
        name: 'apps-calendar',
        component: () => import('@/views/apps/calendar/Calendar.vue'),
    },


    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
        path: '/apps/todo',
        name: 'apps-todo',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
        },
    },
    {
        path: '/apps/todo/:filter',
        name: 'apps-todo-filter',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
            if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: '/apps/todo/tag/:tag',
        name: 'apps-todo-tag',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
            if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
            else next({ name: 'error-404' })
        },
    },

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*

    /*--------------App---------------*/

    {
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import('@/views/others/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/auth/login',
        name: 'auth-login',
        component: () =>
            import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    
    {
        path: '/others/coming-soon',
        name: 'misc-coming-soon',
        component: () =>
            import('@/views/others/ComingSoon.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/others/under-maintenance',
        name: 'misc-under-maintenance',
        component: () =>
            import('@/views/others/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/profile',
        name: 'pages-profile',
        component: () =>
            import('@/views/auth/profile/Profile.vue'),
        meta: {
            pageTitle: 'Profile',
            breadcrumb: [{
                text: 'Pages',
            },
            {
                text: 'Profile',
                active: true,
            },
            ],
        },
    },
    {
        path: '/faq',
        name: 'pages-faq',
        component: () =>
            import('@/views/auth/profile/Profile.vue'),
        meta: {
            pageTitle: 'Profile',
            breadcrumb: [{
                text: 'Pages',
            },
            {
                text: 'Profile',
                active: true,
            },
            ],
        },
    },
    // *--------------------------------------------------------------------
    {
        path: '/admin/group-mgmt',
        name: 'group-mgmt',
        component: () => import('@/views/admin/Group.vue'),
        meta: {
            pageTitle: 'Quản lý quyền',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Group Management',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admin/user-mgmt',
        name: 'user-mgmt',
        component: () => import('@/views/admin/User.vue'),
        meta: {
            pageTitle: 'Quản lý người dùng',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'User Management',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admin/report-mgmt',
        name: 'report-mgmt',
        component: () => import('@/views/admin/Report.vue'),
        meta: {
            pageTitle: 'Quản lý báo cáo',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Report Management',
                    active: true,
                },
            ],
        },
    },
]